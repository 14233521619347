export default {
  token: (state) => {
    let access_token =
        state.access_token || sessionStorage.getItem('access_token'),
      refresh_token =
        state.refresh_token || sessionStorage.getItem('refresh_token')

    return {
      access_token,
      refresh_token,
    }
  },
  loggedIn: (state, getters) => {
    return getters.token.access_token && getters.token.refresh_token
  },
}
