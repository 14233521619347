export default [
  {
    btnText: '信息披露声明',
  },
  {
    btnText: '机构备案信息',
  },
  {
    btnText: '机构组织信息',
  },
  {
    btnText: '机构审核信息',
  },
  {
    btnText: '机构经营信息',
  },
  {
    btnText: '重大事项',
  },
  {
    btnText: '其他信息',
  },
  {
    btnText: '平台运营报告',
  },
  {
    btnText: '网络借贷知识普及',
  },
]
