import http from './http'

/**
 * 连连支付相关
 */

export default {
  /**
   * 提现
   * @param {number} amount 提现金额
   * @returns
   */
  withdraw(amount) {
    return http.post('/lianlianPay/withdraw', { amount })
  },

  /**
   * 我的银行卡
   * @returns
   */
  bankCard() {
    return http.post('/lianlianPay/bank_card')
  },

  /**
   * 提现手续费
   * @param {number} amount 提现金额
   * @returns
   */
  withdrawFee(amount) {
    return http.post('/lianlianPay/withdraw_fee', { amount })
  },
  /**
   * 绑定银行卡
   * @param {*} params
   * @returns
   */
  bindBankCard(params) {
    return http.post('/lianlianPay/bind_bank_card', params)
  },
  /**
   * 连连支付银行卡删除(解绑)
   * @param {*} params
   * @returns
   */
  unbindBankCard(params) {
    return http.post('/lianlianPay/unbind_bank_card', params)
  },
}
