import http from './http'

/**
 * 用户
 */

export default {
  /**
   *
   * @typedef {Object} LoginPayload
   * @property {string} username 用户名
   * @property {string} password 密码
   * @property {string} [remme] 记住我
   * @property {string} [from] 来源
   * @property {string} [vcode] 图形验证码
   * @property {string} [ticket] 图形验证码ticket
   */
  /**
   * 登录
   * @param {LoginPayload} params {@link LoginPayload}
   * @returns
   */
  login(params) {
    return http.post('/user/login', params)
  },

  /**
   *
   * @typedef {Object} RefreshTokenPayload
   * @property {string} refresh_token Token
   * @property {string} from 来源
   */
  /**
   * 刷新 AccessToken
   * @param {RefreshTokenPayload} params {@link RefreshTokenPayload}
   * @returns
   */
  refreshToken(params) {
    return http.get('/user/refresh', { params })
  },

  /**
   * 获取用户基本信息
   * @returns
   */
  userInfo() {
    return http.get('/user/myinfo')
  },

  /**
   * @typedef ResetPwdPayload
   * @property {string} old_password 旧密码
   * @property {string} new_password 新密码
   */
  /**
   * 修改登录密码
   * @param {ResetPwdPayload} params {@link ResetPwdPayload}
   * @returns
   */
  resetPwd(params) {
    return http.post('/user/resetpwd', params)
  },

  /**
   * @typedef {Object} MessagesPayload
   * @property {number} page
   * @property {number} page_size
   */
  /**
   * 消息中心
   * @param {MessagesPayload} {@link MessagesPayload}
   * @returns
   */
  messages(params) {
    return http.get('/account/messages', { params })
  },

  /**
   * 消息中心-详情
   * @param {number} id 消息id
   * @returns
   */
  messagesDetail(id) {
    return http.get('/account/messagedetail', { params: { id } })
  },

  /**
   * 个人信息
   * @returns
   */
  personalInfo() {
    return http.get('/account/personalinfo')
  },
}
