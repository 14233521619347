import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import to from 'await-to-js'

import vueWaves from './directives/waves'
import listAnimation from './animation/list'
import { Dialog } from 'vant'
import 'amfe-flexible'

Vue.config.productionTip = false
window.to = to

Vue.use(vueWaves)
Vue.use(listAnimation)

// 全局注册Dialog供组件形式调用
Vue.component('Dialog', Dialog.Component)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
