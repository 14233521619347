import http from './http'

// 接口列表
export default {
  // 帮助中西导航
  ArtHelpMenu(params) {
    return http.get('/article/helpmenu', { params })
  },
  // 帮助中西内容
  ArtHelpList(params) {
    return http.get('/article/helplist', { params })
  },
  // 媒体报道 详情
  ArtMideaReportDetail(params) {
    return http.get('/article/noticedetail', { params })
  },
  // 关于我们 网站公告 媒体报道
  ArtAnnouncementNotice(params) {
    return http.get('/article/noticelist', { params })
  },
  // 关于我们 网站公告 详情
  ArtAnnouncementDetail(params) {
    return http.get('/article/noticedetail', { params })
  },
  // 关于我们 友情链接
  ArtAboutUsLinks(params) {
    return http.get('/article/links', { params })
  },
}
