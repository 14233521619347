import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'

Vue.use(VueRouter)

const originalReplace = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (store.getters.loggedIn) {
      next()
    } else {
      // 跳转登录页面 携带to route url
      next({
        name: 'Signin',
        query: {
          to: to.fullPath,
        },
      })
    }
  }
  next()
})

export default router
