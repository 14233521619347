import account from './account'
import general from './general'
import user from './user'
import lianlian_pay from './lianlian_pay'
import others from './others'

export { account, general, user, lianlian_pay }

export default {
  account,
  general,
  user,
  lianlian_pay,
  others,
}
