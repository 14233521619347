export default () => ({
  access_token: '',
  refresh_token: '',

  // 合规运营数据
  guideData: null,

  // 银行卡信息
  bankCardData: null,
})
