import http from './http'

/**
 * 账户
 */

export default {
  /**
   * 账户信息
   * @returns
   */
  accountInfo() {
    return http.get('/account/info')
  },

  /**
   * @typedef {Object} AccountLogsPayload
   * @property {number} page 页数
   * @property {number} page_size 条数
   * @property {string} start 开始时间
   * @property {string} end 结束时间
   * @property {number} trade_type 类型(0-所有 1-充值 2-提现 3-出借 4-回款)
   */
  /**
   * 账户流水
   * @param {AccountLogsPayload} params {@link AccountLogsPayload}
   * @returns
   */
  accountLogs(params) {
    return http.get('/account/accountlogs', { params })
  },

  /**
   * @typedef {Object} UserAddinRecordPayload
   * @property {number} page 页数
   * @property {number} page_size 每页条数
   */
  /**
   * 用户投标记录
   * @param {UserAddinRecordPayload} params {@link UserAddinRecordPayload}
   * @returns
   */
  userAddinRecord(params) {
    return http.get('/account/useraddinrecord', { params })
  },

  /**
   * @typedef {Object} UserInvestRecordPayload
   * @property {number} page 页数
   * @property {number} page_size 每页条数
   * @property {number} [project_set_id] 项目集合ID
   */
  /**
   * 用户直投记录
   * @param {UserInvestRecordPayload} params {@link UserInvestRecordPayload}
   * @returns
   */
  userInvestRecord(params) {
    return http.get('/account/userinvestrecord', { params })
  },

  /**
   * 用户集合投标详情
   * @param {number} id 集合投资id
   * @returns
   */
  userAddinDetail(id) {
    return http.get('/account/useraddindetail', { params: { id } })
  },

  /**
   * @typedef {Object} MyAssetsPayload
   * @property {number} page 页数
   * @property {number} page_size 每页条数
   * @property {number} status 状态（1-持有中 2-已完成）
   */
  /**
   * 我的资产-列表
   * @param {MyAssetsPayload} params {@link MyAssetsPayload}
   * @returns
   */
  myAssets(params) {
    return http.get('/account/myassets', { params })
  },

  /**
   * 我的资产-项目详情
   * @param {number} id 投标记录id
   * @returns
   */
  myAssetsDetail(id) {
    return http.get('/account/myassetsdetail', { params: { id } })
  },

  /**
   * 我的资产-详情-还款计划
   * @param {number} id 投资记录id
   * @returns
   */
  myAssetsRepays(id) {
    return http.get('/account/myassetsrepays', { params: { id } })
  },

  /**
   * @typedef {Object} MyAssetsTransfersPayload
   * @property {number} page 页数
   * @property {number} page_size 每页条数
   * @property {number} id 投资记录id
   */
  /**
   * 我的资产-详情-转让记录
   * @param {MyAssetsTransfersPayload} params {@link MyAssetsTransfersPayload}
   * @returns
   */
  myAssetsTransfers(params) {
    return http.get('/account/myassetstransfers', { params })
  },

  /**
   * @typedef {Object} OldInvestRecordPayload
   * @property {number} page 页数
   * @property {number} page_size 每页条数
   * @property {number} type 类型（1-直投 2-债权）
   */
  /**
   * 我的资产-历史记录
   * @param {OldInvestRecordPayload} params {@link OldInvestRecordPayload}
   * @returns
   */
  oldInvestRecord(params) {
    return http.get('/account/oldinvestrecord', { params })
  },
}
