import types from './types'
import api from '../api'
import to from 'await-to-js'

export default {
  async login({ commit }, params) {
    let error, response
    ;[error, response] = await to(
      api.user.login({ ...{ from: 'wap' }, ...params })
    )
    // 网络错误
    if (error) {
      throw error
    }
    // 登录失败
    if (response?.data?.code !== -1) {
      throw response.data.msg
    }

    commit(types.SET_TOKEN, response.data)
    // TODO 待删除保存密码
    sessionStorage.setItem('USERNAME', params.username)
    sessionStorage.setItem('PASSWORD', params.password)
    // end

    return response
  },
  async requestAccountInfo() {
    let error, response
    ;[error, response] = await to(api.account.accountInfo())

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data.data
    }

    throw '获取账户信息失败'
  },
  async requestUserInfo() {
    let error, response
    ;[error, response] = await to(api.user.userInfo())

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data.data
    }

    throw '获取个人基本信息失败'
  },
  async requestPersonalInfo() {
    let error, response
    ;[error, response] = await to(api.user.personalInfo())

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data.data
    }

    throw '获取个人基本信息失败'
  },
  async requestMessages({ commit }, { page, pageSize }) {
    let error, response
    ;[error, response] = await to(
      api.user.messages({
        page,
        page_size: pageSize,
      })
    )
    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data
    }

    throw '获取消息失败'
  },
  async requestMessageDetail({ commit }, id) {
    let error, response
    ;[error, response] = await to(api.user.messagesDetail(id))

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data.data
    }

    throw '获取消息内容失败'
  },

  async requestMyAssets({ commit }, { page, pageSize, status }) {
    let error, response
    ;[error, response] = await to(
      api.account.myAssets({
        page,
        page_size: pageSize,
        status,
      })
    )

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data
    }

    throw '获取我的资产失败'
  },
  async requestMyAssetsDetail({ commit }, id) {
    let error, response
    ;[error, response] = await to(api.account.myAssetsDetail(id))

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data.data
    }

    throw '获取资产详情失败'
  },
  async requestMyAssetsRepays({ commit }, id) {
    let error, response
    ;[error, response] = await to(api.account.myAssetsRepays(id))

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data.data
    }

    throw '获取还款计划失败'
  },
  async requestMyAssetsTransfers({ commit }, { id, page, pageSize }) {
    let error, response
    ;[error, response] = await to(
      api.account.myAssetsTransfers({
        id,
        page,
        page_size: pageSize,
      })
    )

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data
    }

    throw '获取转让记录失败'
  },
  async requestOldInvestRecord({ commit }, { type, page, pageSize }) {
    let error, response
    ;[error, response] = await to(
      api.account.oldInvestRecord({
        type,
        page,
        page_size: pageSize,
      })
    )

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data
    }

    throw '获取历史记录失败'
  },
  async requestAccountLogs(
    { commit },
    { page, pageSize, start, end, tradeType }
  ) {
    let error, response
    ;[error, response] = await to(
      api.account.accountLogs({
        page,
        page_size: pageSize,
        start,
        end,
        trade_type: tradeType,
      })
    )

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data
    }

    throw '获取账户流水失败'
  },
  async requestAddinRecord({ commit }, { page, pageSize }) {
    let error, response
    ;[error, response] = await to(
      api.account.userAddinRecord({
        page,
        page_size: pageSize,
      })
    )

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data
    }

    throw '获取投标记录失败'
  },
  async requestUserInvestRecord({ commit }, { page, pageSize, projectSetId }) {
    let error, response
    ;[error, response] = await to(
      api.account.userInvestRecord({
        page,
        page_size: pageSize,
        project_set_id: projectSetId,
      })
    )

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data
    }

    throw '获取直投记录失败'
  },
  async requestUserAddinDetail({ commit }, id) {
    let error, response
    ;[error, response] = await to(api.account.userAddinDetail(id))

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data.data
    }

    throw '获取集合投标详情失败'
  },

  async requestResetPwd({ commit }, { old_password, new_password }) {
    let error, response
    ;[error, response] = await to(
      api.user.resetPwd({ old_password, new_password })
    )

    if (error) {
      throw error
    }

    if (response?.data) {
      // 返回code外部判断
      return response.data.code
    }

    throw '修改密码失败'
  },

  clearLoginStatus({ commit }) {
    // 删除sessionStorage
    sessionStorage.removeItem('USERNAME')
    sessionStorage.removeItem('PASSWORD')
    sessionStorage.removeItem('access_token')
    sessionStorage.removeItem('refresh_token')
    // 删除Cookie
    // TODO
    // 删除State
    commit(types.SET_TOKEN, { access_token: '', refresh_token: '' })
  },

  async requestGuideData({ commit, state }) {
    if (state.guideData != null && Object.keys(state.guideData).length > 0) {
      return state.guideData
    }

    let error, response
    ;[error, response] = await to(api.general.artNewUserGuide())

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      commit(types.SET_GUIDE_DATA, response.data.data)
      return response.data.data
    }

    throw '获取合规运营数据失败'
  },
  async requestLlpWithdraw({ commit }, amount) {
    let error, response
    ;[error, response] = await to(api.lianlian_pay.withdraw(amount))

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data
    } else if (response?.data?.code === 406) {
      throw response.data.msg || '提现失败'
    }

    throw '提现失败'
  },
  async requestLlpWithdrawFee({ commit }, amount) {
    let error, response
    ;[error, response] = await to(api.lianlian_pay.withdrawFee(amount))

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      return response.data
    }

    throw '获取提现手续费失败'
  },
  async requestBankCard({ state, commit }) {
    if (state.bankCardData) {
      return state.bankCardData
    }

    let error, response, bankCardData
    ;[error, response] = await to(api.lianlian_pay.bankCard())

    if (error) {
      throw error
    }

    if (response?.data?.code === -1) {
      bankCardData = response.data.data.account ? response.data.data : null

      commit(types.SET_BANK_CARD_DATA, bankCardData)
      return bankCardData
    }

    throw '获取绑定银行卡失败'
  },
}
