import http from './http'

/**
 * 通用
 */

export default {
  /**
   * 获取服务器时间
   * @returns
   */
  serverTime() {
    return http.get('/general/time')
  },

  /**
   * 测试API TOKEN有效性
   * @returns
   */
  testToken() {
    return http.get('/general/testtoken')
  },

  /**
   * @typedef {Object} BannerPayload
   * @property {string} position 位置
   * @property {number} [limit] 条数
   */
  /**
   * 获取banner
   * @param {BannerPayload} params {@link BannerPayload}
   * @returns
   */
  banner(params) {
    return http.get('/general/banner', { params })
  },

  /**
   * 获取平台公告
   * @param {string} position 位置
   * @returns
   */
  notice(position) {
    return http.get('/general/notice', { params: { position } })
  },

  /**
   * 图文验证码
   * @returns
   */
  vcode() {
    return http.get('/general/vcode')
  },

  /**
   *
   * @typedef {Object} NoticeListPayload
   * @property {string} code 标识(公告:notice)
   * @property {number} page
   * @property {number} page_size
   */
  /**
   * 网站公告（媒体报道可用）
   * @param {NoticeListPayload} params {@link NoticeListPayload}
   * @returns
   */
  noticeList(params) {
    return http.get('/article/noticelist', { params })
  },

  /**
   * 网站公告-详情（媒体报道可用）
   * @param {number} id 内容id
   * @returns
   */
  noticeDetail(id) {
    return http.get('/article/noticedetail', { params: { id } })
  },

  /**
   * 信息披露数据
   * @returns
   */
  informationDisclosure() {
    return http.get('/general/informationdisclosure')
  },

  artNewUserGuide() {
    return http.get('/general/guide')
  },
}
