import types from './types'

export default {
  [types.SET_TOKEN](state, { access_token, refresh_token }) {
    sessionStorage.setItem('access_token', access_token)
    sessionStorage.setItem('refresh_token', refresh_token)
    state.access_token = access_token
    state.refresh_token = refresh_token
  },
  [types.SET_GUIDE_DATA](state, payload) {
    state.guideData = payload
  },
  [types.SET_BANK_CARD_DATA](state, payload) {
    state.bankCardData = payload
  },
}
