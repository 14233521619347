const App = () => import('@/App.vue')
const Signin = () => import('@/views/Signin.vue')
const Index = () => import('@/views/Index.vue')
const Home = () => import('@/views/Home.vue')
const User = () => import('@/views/My.vue')
const Nothing = () => import('@/views/404NotFound.vue')
const NoticeDetail = () => import('@/views/notice/NoticeDetail.vue')
const NoticeList = () => import('@/views/notice/NoticeList.vue')
const InfoDisCon = () => import('@/views/info_dis/InfoDisCon.vue')
const InfoDisNav = () => import('@/views/info_dis/InfoDisNav.vue')
const RiskControlMeasures = () =>
  import('@/views/risk_control/RiskControlMeasures.vue')

const Personal = () => import('@/views/user/Personal.vue')
const Messages = () => import('@/views/user/Messages.vue')
const MyAssets = () => import('@/views/my_assets/MyAssets.vue')
const AssetsDetail = () => import('@/views/my_assets/AssetsDetail.vue')
const AccountLogs = () => import('@/views/account_logs/AccountLogs.vue')
const AddinRecord = () => import('@/views/addin_record/AddinRecord.vue')
const RepayPlan = () => import('@/views/my_assets/RepayPlan.vue')
const ResetPwd = () => import('@/views/user/ResetPwd.vue')
const Full2017 = () => import('@/views/reports/full_2017/full_2017')
const LlpWithdraw = () => import('@/views/lianlian_pay/LlpWithdraw.vue')
const ComplianceProgress = () =>
  import('@/views/reports/compliance_progress/compliance_progress')
const Half2018 = () => import('@/views/reports/half_2018/half_2018')
const AboutUs = () => import('@/views/about_us/about_us.vue')

import nav from '@/views/info_dis/nav'

export default [
  {
    path: '/',
    redirect: { name: 'Index' },
    component: App,
    children: [
      {
        path: 'signin',
        name: 'Signin',
        component: Signin,
      },
      {
        path: 'index',
        name: 'Index',
        redirect: { name: 'Home' },
        component: Index,
        children: [
          {
            path: 'home',
            name: 'Home',
            component: Home,
          },
          {
            path: 'user',
            name: 'User',
            component: User,
          },
        ],
      },
      {
        path: 'notice',
        name: 'NoticeList',
        component: NoticeList,
      },
      {
        path: 'notice/:id',
        name: 'NoticeDetail',
        component: NoticeDetail,
        props: true,
      },
      {
        path: 'personal',
        name: 'Personal',
        component: Personal,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'messages',
        name: 'Messages',
        component: Messages,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'myassets/:status',
        name: 'MyAssets',
        component: MyAssets,
        meta: {
          requireAuth: true,
        },
        props: true,
      },
      {
        path: 'accountlogs',
        name: 'AccountLogs',
        component: AccountLogs,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'addinrecord',
        name: 'AddinRecord',
        component: AddinRecord,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'assets_detail/:assets_id',
        name: 'AssetsDetail',
        component: AssetsDetail,
        meta: {
          requireAuth: true,
        },
        props: true,
      },
      {
        path: 'risk_control_measures',
        name: 'RiskControlMeasures',
        component: RiskControlMeasures,
      },
      {
        path: 'info_dis_con/:id',
        name: 'InfoDisCon',
        component: InfoDisCon,
        props: (route) => {
          return {
            id: route.params.id,
            title: nav[route.params.id].btnText,
          }
        },
      },
      {
        path: 'info_dis_nav',
        name: 'InfoDisNav',
        component: InfoDisNav,
      },
      // 2017年报
      {
        path: '/full_2017',
        name: 'Full2017',
        component: Full2017,
      },
      // 合规进度
      {
        path: '/compliance',
        name: 'ComplianceProgress',
        component: ComplianceProgress,
      },
      // 2018半年报
      {
        path: '/half_2018',
        name: 'Half2018',
        component: Half2018,
      },
      {
        path: 'repay_plan/:id',
        name: 'RepayPlan',
        component: RepayPlan,
        props: true,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'reset_pwd',
        name: 'ResetPwd',
        component: ResetPwd,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: 'about_us',
        name: 'AboutUs',
        component: AboutUs,
      },
      {
        path: 'llp_withdraw',
        name: 'LlpWithdraw',
        component: LlpWithdraw,
        meta: {
          requireAuth: true,
        },
      },
    ],
  },

  { path: '*', component: Nothing },
]
