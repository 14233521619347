import { animate, easeOut, interpolate } from 'popmotion'

let listAnimation = {}

listAnimation.install = (Vue, options = {}) => {
  Vue.component('ListTransition', {
    functional: true,
    render(h, context) {
      let data = {
        props: {
          name: 'ListTransition',
          ...context.props,
          css: false,
        },
        key: context.data.key,
        on: {
          beforeEnter(el) {
            //
          },
          enter(el, done) {
            //
            let index = (parseInt(el.dataset.index) || 0) + 1,
              pageSize = parseInt(el.dataset.pageSize) || 1
            let multiplier = index % pageSize,
              duration = context.props.duration || 100
            animate({
              to: 0,
              from: 1,
              duration: duration,
              elapsed: -duration * (multiplier === 0 ? pageSize : multiplier),
              ease: easeOut,
              onUpdate: (latest) => {
                let mapToTransform = interpolate(
                    [0, 1],
                    ['translateY(0%)', 'translateY(50%)']
                  ),
                  mapToOpacity = interpolate([0, 1], [1, 0])
                el.style.transform = mapToTransform(latest)
                el.style.opacity = mapToOpacity(latest)
              },
            })
            done()
          },
          afterEnter(el) {
            //
          },
          enterCancelled(el) {
            //
          },
          beforeLeave(el) {
            //
          },
          leave(el, done) {
            //
            done()
          },
          afterLeave(el) {
            //
          },
          leaveCancelled(el) {
            //
          },
        },
      }

      return h('transition-group', data, context.children)
    },
  })
}

export default listAnimation
