import axios from 'axios'
import store from '@/store'
import router from '../router'

// axios 配置
/* eslint-disable */
axios.defaults.baseURL = env.baseUrl
axios.defaults.timeout = env.timeout
/* eslint-disable */
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    const configArg = config
    configArg.headers.Authorization =
      store.state.access_token || sessionStorage.getItem('access_token')
    return configArg
  },
  (err) => Promise.reject(err)
)

// http response 拦截器
axios.interceptors.response.use(
  async (response) => {
    // TODO 刷新Accesstoken避免重登 接口待完成
    // let refreshToken = sessionStorage.getItem('refresh_token')

    // if (response.data.code === 102 && refreshToken) {
    //   let error, response
    //   ;[error, response] = await to(
    //     user.refreshToken({
    //       refresh_token: refreshToken,
    //       from: 'wap',
    //     })
    //   )
    // }

    // 重新登录
    if (response.data.code === 102) {
      let relogin = () => {
        setTimeout(() => {
          if (router.currentRoute.name != 'Signin') {
            router.push({ name: 'Signin' })
          }
        }, 1000)
        return Promise.reject('身份认证过期，请重新登录')
      }

      let username, password
      username = sessionStorage.getItem('USERNAME')
      password = sessionStorage.getItem('PASSWORD')

      if (username && password) {
        let error, loginResponse
        ;[error, loginResponse] = await to(
          store.dispatch('login', { username, password })
        )
        if (loginResponse?.data?.code === -1) {
          return axios.request(response.config)
        }
      }
      return relogin()
    }

    return response
  },
  (error) => {
    if (error.response) {
      switch (error.response.code) {
        case 401:
          // console.log('401');
          break
        case 404:
          alert('404')
          break
        default:
          break
      }
    } else if (error.code === 'ECONNABORTED') {
      throw new Error('请求超时')
    }
    // console.log(JSON.stringify(error));//console : Error: Request failed with status code 402
    return Promise.reject(error)
  }
)

export default axios
